/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
// regular style toast
@import "ngx-toastr/toastr";
@import "animate.css";

:root {
  --font_family: "Poppins";
  --primary: #cae7e0;
  --secondary: #e2fff9;
  --tertiary: #beded7;
  --bg: #009473;
  --color: #535353;
  --font-color: #424242;
}

body {
  background: var(--primary);
  font-family: var(--font_family) !important;
  font-style: normal !important;
}

a.nav-link {
  font-family: var(--font_family);
}
// .card {
//   background-color: var(--tertiary);
//   border-radius: 20px;
//   border: none;
// }

button.navbar-toggler {
  border: none;
}
// app-modal-popup {
//     position: relative;
//     bottom: 44rem;
// }

.btn-light {
  background-color: rgb(123 192 175);
  border: none;
  color: #ffff;
}

.btn:hover {
  color: #ffff;
  background-color: rgb(123 192 175);
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #ffff;
  background-color: rgb(123 192 175);
}

.slider_section {
  height: 500px !important;
}

// section.slider_section {
//   background: var(--bg);
// }
.slider_section .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  background-color: var(--primary);
}

.errors {
  color: red;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--font-color);
  font-weight: 600;
  font-size: 18px;
}

.main-container {
  margin-top: 13rem;
}
.remove_mt {
  margin-top: 6rem !important;
}

@media only screen and (max-width: 995px) {
  .main-container {
    margin-top: 8rem;
  }
  /* For mobile phones: */
  // .nav-link {
  //   margin-right: 0;
  // }
  // ul.navbar-nav {
  //   align-items: center;
  // }
}
